<div id="tool" [className]="this.darkMode ? 'dark-mode' : ''">
    <body>
      <div class="flex-container">
        <div class="import-controls lists">
          <div id="title">
            <h1>VIDEO <p class="green inline">LABELING</p> TOOL</h1>
          </div>
          <h2>Load videos:</h2>
          <input type="file" id="video_input" accept="video/*" (change)="uploadVideo()" multiple><br>
          <p class="fixed-height">Current File: {{this.currentFileName}}</p>
          <h2>Load preset:</h2>
          <input type="file" id="json_input" accept="application/json" (change)="loadJSON()">          
          <h2>Controls for playback</h2>
          <ul class="flex-container flex-wrap controls-list">
            <li class="control-span">use <b>arrow keys</b> to go 'frame-by-frame'</li>
            <li class="control-span">use <b>v</b> and <b>n</b> to switch between videos</li>
            <li class="control-span">use <b>b</b> to pause or resume the video</li>
            <li class="control-span"><b>drag</b> points or segments in the view to change them</li>
            <li class="control-span"><b>hover</b> over segments to see the label (labels may take a while to be displayed)</li>

          </ul>
          <table class='point-list'>
            <caption>Points</caption>
            <thead>
              <tr>
                <th>ID</th>
                <th>Label</th>
                <th>Time</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let point of getCurrentPoints().reverse(); index as i">
                <td>{{point.pid}}</td>
                <td>{{point.labelText}}</td>
                <td>{{point.time.toFixed(4)}}</td>
                <td><button class="del-button" (click)="deletePoint(getCurrentPoints().length - 1 - i, point.pid)" tabindex="-1">Delete</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="video-container lists">
          <video id="video" class="video" [src]="fileUrl" width="640" height="360" controls  [muted]="'muted'"></video>
          <div id="zoomviewContainer" class="zoomview-container"></div>
          <div id="overviewContainer" class="overview-container"></div>
          <audio #audio></audio>
          <table class='segment-list'>
            <caption>Segments</caption>
            <thead>
              <tr>
                <th>ID</th>
                <th>Label</th>
                <th>Start</th>
                <th>End</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let segment of getCurrentSegments().reverse(); index as i" ng-if="getCurrentSegments().length > 0">
                <td>{{segment?.pid}}</td>
                <td>{{segment?.labelText}}</td>
                <td>{{segment?.startTime.toFixed(4)}}</td>
                <td>{{segment?.endTime.toFixed(4)}}</td>
                <td><button class="del-button" (click)="deleteSegment(getCurrentSegments().length - 1 - i, segment?.pid)" tabindex="-1">Delete</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="label-controls">
          <a href="https://haawai.mb.tu-dortmund.de/"><img class="logo" src="../../assets/logos/haawai_logo.png"></a>
          <h2>Controls for labeling</h2>
          <button class="button" (click)="addPointButton()">add point</button>
          <button class="button" (click)="addSegmentButton()">add segment</button>
          <button class="button" (click)="export()">export JSON</button>
          <button class="button" (click)="toggleDarkMode()">"Darkmode" toggle</button> <br>
          <h2>Import JSON:</h2><input type="file" id="data_input" accept="application/json" (change)="importData()" multiple>
          <h3>Points</h3>
          <div id="points" class=""></div>
          <h3>Segments</h3>
          <div id="segments" class=""></div>
        </div>
      </div>
      <div class="lists">
      </div>
    </body>
    <footer class="footer flex-container">
      <a href="https://ips.mb.tu-dortmund.de/impressum/">Impressum</a>
      <a href="https://ips.mb.tu-dortmund.de/datenschutzerklaerung/">Datenschutzerklärung</a>
    </footer>
</div>